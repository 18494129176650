import React, { useState, useEffect } from "react";
import logo from "../../assets/accial-logo.png";
import background from "../../assets/login-background.png";
import styles from "./Signin.module.css";
import NewPasswordInput from "../../components/Inputs/NewPasswordInput";
import { ACCIAL, PARTNER, INVESTOR, COINVESTOR, TRANSACTIONADMIN, FACILITY } from "../../catalogs/roles";
import usersApi from "../../api/users";
import { store } from "../../redux/store";
import { userSettings } from '../../redux/actions/user'
import { Input, Form, Button, Alert, Modal } from "antd";
import { Auth } from "aws-amplify";
import { ACCIAL_BLUE } from "../../helpers/colorHelpers";

const Signin = props => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [modalAlert, setModalAlert] = useState(null);
  const [user, setUser] = useState(null);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [MFAAuthenticationVisible, setMFAAuthenticationVisible] = useState(false);
  const [challengeAnswer, setChallengeAnswer] = useState(null);

  //PRINCIPAL FORM
  const signIn = () => {
    setAlert(null);
    setLoading(true);

    if (!email || email === "") {
      setAlert({ type: "error", message: "Email is required." });
      setLoading(false);
      return;
    }

    if (!password || password === "") {
      setAlert({ type: "error", message: "Password is required." });
      setLoading(false);
      return;
    }

    Auth.signIn({ username: email, password })
      .then(user => {
        setLoading(false);
        var isFirstLogin = false;

        if (user.attributes) {
          if (user.attributes["custom:IsFirstLogin"]) {
            isFirstLogin = user.attributes["custom:IsFirstLogin"] ? user.attributes["custom:IsFirstLogin"] === "1" : false;
          }
        }

        if (user.challengeName === "NEW_PASSWORD_REQUIRED" || isFirstLogin) {
          setUser(user);
          showModal();
          return;
        }
        else if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
          setUser(user);
          setMFAAuthenticationVisible(true);
          return;
        }
        else if (user.challengeName === "MFA_SETUP" || user.preferredMFA === "NOMFA") {
          setUser(user);
          props.history.push("/mfasetup");
        }
        else {
          setLoading(false);
          setAlert({ type: "error", message: "Unable to connect. Please contact ACCIAL." });
          return;
        }

      })
      .catch(err => {
        setLoading(false);
        //To avoid give this information to the user.
        if (err.code === "UserNotFoundException") {
          setAlert({ type: "error", message: "Incorrect username or password." });
        } else {
          setAlert({ type: "error", message: "Incorrect username or password." });
        }
      });
  };
  //PRINCIPAL FORM

  //MODAL FIRST LOGIN CHANGE PASSWORD
  const showModal = () => {
    setChangePasswordVisible(true);
  };

  const handleOk = () => {
    onSubmitChangePassword();
  };

  const handleCancel = () => {
    setChangePasswordVisible(false);
    setNewPassword(null);
    setRepeatNewPassword(null);
    setModalAlert(null);
  };

  const onSubmitChangePassword = async () => {
    setAlert(null);
    setModalAlert(null);
    setLoading(true);

    if (newPassword !== repeatNewPassword) {
      setModalAlert({ type: "error", message: "New password does not match" });
      setLoading(false);
      return;
    }

    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.changePassword(user, password, newPassword)
          .then(() => {
            setPassword(null);
            setNewPassword(null);
            setRepeatNewPassword(null);

            Auth.updateUserAttributes(user, { "custom:IsFirstLogin": "0" })
              .then(() => {
                props.history.push("/mfasetup");
              })
              .catch(err => {
                setModalAlert({ type: "error", message: "Unable to start setup for Multi-Factor Authentication (MFA). Please contact Accial support" });
                setLoading(false);
                return;
              })
          })
          .catch(err => {
            setModalAlert({ type: "error", message: "Unable to change the password. Please contact Accial support" });
          });
      })
      .catch(err => {
        setModalAlert({ type: "error", message: "Unable to change the password. Please contact Accial support" });
      })
      .finally(setLoading(false));
  };
  //MODAL FIRST LOGIN CHANGE PASSWORD

  //MODAL MFA
  useEffect(() => {
    if (challengeAnswer) {
      if (challengeAnswer.length == 6) {
        var onlyNumbers = challengeAnswer.match(/^\d+$/);
        if (onlyNumbers != null) {
          setDisabled(false);
          return;
        }
      }
    }
    setDisabled(true);
    return;
  }, [challengeAnswer]);

  const MFAAuthenticationOk = (e) => {
    e.preventDefault();
    setModalAlert(null);
    setLoading(true);
    Auth.confirmSignIn(user, challengeAnswer, "SOFTWARE_TOKEN_MFA")
      .then(result => {

        const roles = user.signInUserSession.accessToken.payload["cognito:groups"];
        if (
          roles &&
          roles.length > 0 &&
          (roles.includes(ACCIAL) || roles.includes(COINVESTOR)
            || roles.includes(FACILITY) || roles.includes(INVESTOR)
            || roles.includes(PARTNER) || roles.includes(TRANSACTIONADMIN))
        ) {
          //Store user permissions
          if (roles.includes(ACCIAL)) {
            usersApi.getUserPermissions().then(result => {
              store.dispatch(userSettings({ teams: result.teams, permissions: result.permissions }))
            });
          }
        }

        if (props.location && props.location.state && props.location.state.from) {
          props.history.push(props.location.state.from);
        }
        else {
          props.history.push("/app/home");
        };

      })
      .catch(err => {
        setModalAlert({ type: "error", message: `Unable to verify the token.` })
        setLoading(false);
      });
  }

  const MFAAuthenticationCancel = () => {
    setMFAAuthenticationVisible(false);
    setChallengeAnswer(null);
    setModalAlert(null);
  };
  //MODAL MFA

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${background})` }}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" width={125} />
          <div style={{ marginTop: 30, width: 300 }}>
            {alert && (
              <Form.Item>
                <Alert message={alert.message} type={alert.type} closable />
              </Form.Item>
            )}
            <Form.Item>
              <Input
                placeholder="Email"
                name="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onPressEnter={signIn}
              />
            </Form.Item>
            <Form.Item>
              <Input
                placeholder="Password"
                name="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
                onPressEnter={signIn}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" block onClick={signIn} loading={loading}>
                Sign in
              </Button>
              <a href="/forgotpassword" style={{ float: "left", color: ACCIAL_BLUE }}>
                Forgot password
              </a>
            </Form.Item>
          </div>
          <div>
            <Modal
              title="Change your password"
              visible={changePasswordVisible}
              onOk={handleOk}
              okButtonProps={{ disabled: disabled }}
              onCancel={handleCancel}
              width={408}
              maskClosable={false}
            >
              <div>
                <div className={styles.logo}>
                  <img src={logo} alt="logo" width={125} />
                  <div style={{ marginTop: 30, width: 300 }}>
                    {modalAlert && (
                      <Form.Item>
                        <Alert message={modalAlert.message} type={modalAlert.type} closable />
                      </Form.Item>
                    )}
                    <Form.Item>
                      <NewPasswordInput
                        placeholder="New Password"
                        onChange={setNewPassword}
                        value={newPassword}
                        onPassValidations={setDisabled} />
                    </Form.Item>
                    <Form.Item>
                      <Input
                        placeholder="Repeat New Password"
                        value={repeatNewPassword}
                        onChange={e => setRepeatNewPassword(e.target.value)}
                        type="password"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div>
            <Modal
              title="Multi-Factor Authentication"
              visible={MFAAuthenticationVisible}
              onOk={MFAAuthenticationOk}
              okButtonProps={{ disabled: disabled }}
              onCancel={MFAAuthenticationCancel}
              width={408}
              maskClosable={false}
            >
              <div>
                <div className={styles.logo}>
                  <img src={logo} alt="logo" width={125} />
                  <div style={{ marginTop: 30, width: 300 }}>
                    {modalAlert && (
                      <Form.Item>
                        <Alert message={modalAlert.message} type={modalAlert.type} closable />
                      </Form.Item>
                    )}
                    <div>
                      <label className={styles.mfaInput}>
                        Enter an MFA code to complete sign-in
                      </label>
                    </div>
                    <Form.Item>
                      <Input
                        placeholder="MFA Code"
                        name="Code"
                        value={challengeAnswer}
                        onChange={e => setChallengeAnswer(e.target.value)}
                        onPressEnter={MFAAuthenticationOk}
                        autoFocus
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
